<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.pid"
                      placeholder="请选择..."
                      @on-change="changePro">
                <Option :value="v.id"
                        v-for="v in proList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.cid"
                      placeholder="请选择..."
                      @on-change="changeCity">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.sid"
                      placeholder="请选择..."
                      @on-change="changeStore">
                <Option :value="v.id"
                        v-for="v in storeList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="报告时间">
          <Date-picker
            format="yyyy-MM-dd"
            type="daterange"
            placeholder="选择日期"
            @on-change="handleChangeDate"
            style="width:300px;margin-right:10px;"></Date-picker>
          <Button type="primary" @click="handleQuery">搜索</Button>
        </Form-item>
      </Form>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">心电评估报告</h2>
      <div class="content-body">
        <Table
          :columns="heartReportColumns"
          :data="heartReportList"
        ></Table>
        <div>
          <span class="records">共 {{heartReportTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="heartReportTotal"
              :current="heartReportCurrentPage"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
       <span>心电数据</span>
       <Button type="primary" @click="onClickImportData">导入心电数据</Button>
      </h2>
      <div class="content-body">
        <Table
          :columns="heartDataColumns"
          :data="heartDataList"
        ></Table>
        <div>
          <span class="records">共 {{heartDataTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="heartDataTotal"
              :current="heartDataCurrentPage"
              :page-size="heartData.size"
              @on-change="changeDataPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
			v-model="showModal"
      title="心电数据导入"
			class-name="vertical-center-modal"
			:mask-closable="false">
      <div style="display:flex;align-items: center;">
        <span>导入 Excel：</span>
        <Upload
          ref="upload"
          :data="{member_id: uid}"
          :show-upload-list="false"
          :on-success="handleSuccess"
          :format="['xls','xlsx']"
          :on-format-error="handleFormatError"
          action="/api/xnwy/xdupload">
          <Button type="primary">选择文件并导入</Button>
        </Upload>
      </div>
      <div slot="footer">
        <Button @click="showModal = false">取消</Button>
      </div>
		</Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import comparisonService from '@/services/comparisonService';
import qualityService from '@/services/qualityService';
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			showModal: false,
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			heartData: {
				page: 1,
				size: 10,
				xm: 3,
			},
			heartDataColumns: [
				{ title: '门店', key: 'md', align: 'center' },
				{ title: '顾客姓名', key: 'gkmc', align: 'center' },
				{ title: '上传日期', key: 'scrq', align: 'center' },
				{ title: '项目', key: 'xm', align: 'center' },
				{ title: '出具报告时间', key: 'rq', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						return h(
							'a',
							{
								style: {
									color: '#ff8400',
								},
								attrs: {
									target: '_blank',
									href: `/#/base/member/detail/${this.uid}/ecg/summary/${params.row.id}/evaluate`,
								},
							},
							'生成报告',
						);
					},
				},
			],
			heartDataList: [],
			heartDataTotal: 0,
			heartDataCurrentPage: 1,
			reportData: {
				type: 1,
				month_flag: 0,
				pic_type: 34,
				page: 1,
				size: 10,
			},
			heartReportColumns: [
				{ title: '门店', key: 'md', align: 'center' },
				{ title: '顾客姓名', key: 'gkmc', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '出具日期', key: 'cjrq', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const url = params.row.url;
						return h('Icon', {
							attrs: {
								type: 'md-arrow-round-down',
								size: 22,
								color: '#fdad00',
							},
							style: {
								marginLeft: '10px',
								cursor: 'pointer',
							},
							on: {
								click: () => {
									const a = document.createElement('a');
									a.href = url;
									a.style.display = 'none';
									a.setAttribute('download', decodeURI(url.substring(url.lastIndexOf('/') + 1)));
									document.body.appendChild(a);
									a.click();
									document.body.removeChild(a);
								},
							},
						});
					},
				},
			],
			heartReportList: [],
			heartReportTotal: 0,
			heartReportCurrentPage: 1,
			selectedReportItem: [],
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		handleQuery() {
			if (!this.uid) {
				this.$Message.error('请选择会员');
				return;
			}
			this.getHeartData();
			this.getReportData();
		},
		getHeartData() {
			this.heartData.member_id = this.uid;
			MemberService.getECGList(this.heartData).then((data) => {
				this.heartDataList = data.list;
				this.heartDataTotal = data.row_size;
				this.heartDataCurrentPage = +data.cur_page;
			});
		},
		changeDataPage(page) {
			this.heartData.page = page;
			this.getHeartData();
		},
		handleChangeDate(value) {
			this.trainData.start = value[0];
			this.trainData.end = value[1];
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.record_id);
		},
		getReportData() {
			this.reportData.member_id = this.uid;
			MemberService.getReportList(this.reportData).then((data) => {
				this.heartReportList = data.list;
				this.heartReportTotal = data.row_size;
				this.heartReportCurrentPage = +data.cur_page;
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportData();
		},
		handleSuccess(res) {
			const params = {
				member_id: this.uid,
				xm: 3,
				sjrq: res.d.sjrq,
				nr: JSON.stringify(res.d.table),
				file_id: res.d.file_id,
			};
			MemberService.submitECGData(params).then(() => {
				this.showModal = false;
				this.$Message.success('导入成功');
				this.heartData.page = 1;
				this.getHeartData();
			});
		},
		handleFormatError(file) {
			this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传 xls 或 xlsx 格式的文件。');
		},
		onClickImportData() {
			if (!this.uid) {
				this.$Message.error('请选择会员');
				return;
			}
			this.showModal = true;
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}
</style>
